<template>
  <div class="w-full px-gap2 lg:container">
    <div class="-mx-gap flex flex-wrap">
      <div class="column w-12/12 bg-white pt-3 text-left md:p-4">
        <RichTextRenderer
          v-if="modules.content"
          :document="modules.content"
          :node-renderers="nodeRenderers"
        />
        <TabsTheTabs
          v-model="isActive"
          class="tabs__container"
          tab-classes="text-lg uppercase tracking-wide opacity-50 text-blue-dark font-bold mr-2 transition-colors duration-200 ease-in-out mb-1 md:mb-0 text-center"
          header-classes="justify-start flex mb-3 flex-wrap"
        >
          <TabsTheTab
            v-for="(tab, index) in modules.tabs"
            :key="index"
            :title="tab.fields.label"
            :is-active="index === isActive"
          >
            <div class="-mx-gap flex flex-wrap">
              <div
                v-if="tab.fields.image"
                class="column w-12/12 px-gap md:w-6/12 lg:w-8/12"
              >
                <WrappersPictureWrapper :image="tab.fields.image.fields" />
              </div>
              <div
                v-if="tab.fields.tabContent"
                class="column w-12/12 px-gap pl-2 md:ml-1/12 md:w-4/12 lg:ml-0"
              >
                <RichTextRenderer
                  :document="tab.fields.tabContent"
                  :node-renderers="nodeRenderers"
                />
              </div>
            </div>
          </TabsTheTab>
        </TabsTheTabs>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: ['modules'],
    setup() {
      const isActive = ref(0);
      return {
        isActive,
      };
    },
  };
</script>
<style lang="scss" scoped>
  :deep(li.tab__selected) {
    opacity: 1;
    border-bottom: 3px solid #1dcad3;
  }

  :deep(ul.tabs__header li:not(.tab__selected)) {
    border-bottom: 3px solid transparent;
    &:hover {
      opacity: 0.6;
      border-bottom: 3px solid rgba(0, 38, 62, 0.3);
    }
  }
</style>
